<template>
  <div>
    <b-row>
      <b-col
        lg="8"
        sm="12"
        class="mx-auto"
      >
        <b-card-actions
          v-if="!isAffiliate"
          title="Subscription"
          sub-title="View & Update Package"
          icon="ShoppingCartIcon"
          action-collapse
        >
          <b-card-text class="pt-md-1 pt-0">
            <div>
              <center>
                <a
                  href="https://www.trafficmanager.com/pricing#m-pricing-section"
                  target="_blank"
                >
                  <info-icon
                    size="1.5x"
                    class="custom-class"
                  />
                  Package Details.
                </a>
              </center>
              <br>
            </div>

            <b-card
              v-if="isRenewSubscription"
              border-variant="primary"
              header="RENEW SUBSCRIPTION"
              header-bg-variant="primary"
              header-text-variant="white"
              align="center"
            >
              <b-card-text><br>
                <div>
                  <info-icon
                    size="1.5x"
                    class="custom-class"
                  />
                  You can renew your subscription. This will generate a new pro forma invoice.</div>
                <br>
                <b-button
                  variant="primary"
                  :disabled="spinnerRenewSubs"
                  @click="renewSubscription"
                >
                  <span v-if="spinnerRenewSubs">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Renewing...
                  </span>
                  <span v-else>Renew Subscription</span>
                </b-button>
              </b-card-text>
            </b-card>

            <b-row>
              <b-col
                v-if="isNewPackage===false"
                cols="12"
              >
                <div class="text-center mx-3">
                  <p class="mb-0">
                    Package:
                  </p>
                  <b-form-select
                    v-model="curPackage"
                    :options="packageList"
                    style="width:180px"
                    @change="selectPackage"
                  />
                  <p class="mb-0 mt-1">
                    Included:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ includeValue.toLocaleString() }}  {{ curPackageType.toUpperCase() }}
                  </h4>
                </div>
              </b-col>
              <!-- <b-col
                v-if="isNewPackage===false"
                cols="4"
              >
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    Package Type:
                  </p>
                  <b-form-select
                    v-model="curPackageType"
                    :options="['clicks','conversions']"
                    style="width:180px"
                    switch
                    @change="selectPackageType"
                  />
                  <p v-if="isCasino">
                    (Signups and Deposits)
                  </p>
                </div>
              </b-col>
              <b-col
                v-if="isNewPackage===false"
                cols="4"
              >
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    Included:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ includeValue.toLocaleString() }}  {{ curPackageType.toUpperCase() }}
                  </h4>
                </div>
              </b-col> -->
              <b-col
                v-if="isNewPackage"
                cols="12"
                class="text-center"
              >
                <div class="text-center mx-3">
                  <b-form-group
                    class="mb-0"
                    label="Package"
                    label-for="package"
                  >
                    <b-form-select
                      id="package"
                      v-model="curPackage"
                      :options="packageList"
                      style="width:180px"
                      @change="selectPackage"
                    />
                  </b-form-group>
                  <p v-if="isCasino">
                    (Casino)
                  </p>
                  <p v-if="packageType===7003">
                    5000 conversions + 5 million impressions (clicks) included
                  </p>
                  <p v-if="packageType===7004">
                    20.000 conversions + 15 million impressions (clicks) included
                  </p>
                  <p v-if="packageType===7005">
                    50.000 conversions + 25 million impressions (clicks) included
                  </p>
                  <p v-if="packageType===7006">
                    250.000 conversions + 50 million impressions (clicks) included
                  </p>
                  <p v-if="packageType===5003">
                    <strong>(CASINO)</strong><br>
                    20,000 Signups and Deposits +<br>20 million impressions (clicks) included
                  </p>
                  <p v-if="packageType===6003">
                    <strong>(CASINO)</strong><br>
                    50,000 Signups and Deposits +<br>50 million impressions (clicks) included
                  </p>
                </div>
              </b-col>
            </b-row>
            <div
              v-if="cannotChangePackage"
              style="color:darkred"
            >
              <br>
              <center>
                <check-square-icon
                  size="1.5x"
                  class="custom-class"
                />
                Please pay your current pro forma invoice before changing your package.
              </center>
            </div>
          </b-card-text>
        </b-card-actions>

        <b-card-actions
          v-if="!isAffiliate"
          title="Pay Months"
          sub-title="View & Update Billing Cycle"
          icon="CalendarIcon"
          action-collapse
        >
          <b-card-text class="pt-md-1 pt-0">
            <b-row>
              <b-col>
                <div class="text-center mx-3 mt-1">
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    label="Number Of Months To Pay"
                  >
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="payMonths"
                      style="display:flex;flex-direction:row;justify-content:space-evenly; align-items:center; margin: 0 auto; max-width: 55%;"
                      :options="monthsOption"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options-1"
                      @change="changePayMonths"
                    />
                  </b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="spinnerUpdateAccount"
                    @click="updateForm"
                  >
                    <span v-if="spinnerUpdateAccount">
                      <b-spinner
                        small
                        type="grow"
                      />
                      Updating...
                    </span>
                    <span v-else>Save all changes</span>
                  </b-button>
                </div>
              </b-col>
              <b-col v-if="isNextInvoice">
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    Next Invoice Period:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ invoiceContent.Period }}
                  </h4>
                  (GMT time zone)
                </div>
              </b-col>
            </b-row>
            <b-row v-if="isEuplatesc || enableEuplatescCard">
              <b-col>
                <br>
                <b-card
                  border-variant="primary"
                  header="RECURRING PAYMENTS"
                  header-bg-variant="primary"
                  header-text-variant="white"
                  align="center"
                >
                  <b-card-text><br>
                    <div
                      style="text-align:left; padding:10px"
                    >
                      <center>
                        <b-form-radio-group
                          v-if="euplatescCards.length"
                          id="radio-group-3"
                          v-model="selectedCard"
                          :options="euplatescCards"
                          name="radio-options-3"
                          stacked
                          style="padding:10px; text-align:left; width:fit-content; border: 1px solid #cccccc"
                          @change="setDefaultBtn"
                        />
                      </center>
                      <br>
                      <b-button
                        v-if="showSetDefaultBtn"
                        class="btn-sm"
                        style="margin-right:2px"
                        variant="primary"
                        :disabled="spinnerDefaultCC"
                        @click="selectCard"
                      >
                        <span v-if="spinnerDefaultCC">
                          <b-spinner
                            small
                            type="grow"
                          />
                          Setting...
                        </span>
                        <span v-else>
                          <check-icon
                            size="1x"
                            class="custom-class"
                          />
                          Set default
                        </span>
                      </b-button>
                      <b-button
                        class="btn-sm"
                        style="margin-right:2px"
                        variant="primary"
                        :disabled="spinnerAddCC"
                        @click="addCard"
                      >
                        <span v-if="spinnerAddCC">
                          <b-spinner
                            small
                            type="grow"
                          />
                          adding...
                        </span>
                        <span v-else>
                          <plus-icon
                            size="1x"
                            class="custom-class"
                          />
                          Add card
                        </span>
                      </b-button>
                      <b-button
                        v-if="euplatescCards.length"
                        class="btn-sm"
                        variant="primary"
                        :disabled="spinnerRemoveCC"
                        @click="removeCard"
                      >
                        <span v-if="spinnerRemoveCC">
                          <b-spinner
                            small
                            type="grow"
                          />
                          deleting...
                        </span>
                        <span v-else>
                          <x-icon
                            size="1x"
                            class="custom-class"
                          />
                          Delete card
                        </span>
                      </b-button>
                      <br> <br>
                      <p>
                        The selected credit card will be charged automatically on the invoice due date.
                        To disable automatic payments and pay your invoices manually, click on the following button:
                      </p>
                      <br>
                      <b-button
                        v-if="isRecurring"
                        class="btn-sm"
                        variant="primary"
                        :disabled="spinnerDisabledAutoPayCC"
                        @click="cancelCardSubscription"
                      >
                        <span v-if="spinnerDisabledAutoPayCC">
                          <b-spinner
                            small
                            type="grow"
                          />
                          Disabling...
                        </span>
                        <span v-else>
                          <bell-off-icon
                            size="1x"
                            class="custom-class"
                          />
                          Disable recurring payments
                        </span>
                      </b-button>
                      <div
                        v-else
                        style="text-align:center;"
                      >
                        <info-icon
                          size="1.5x"
                          class="custom-class"
                        />
                        Enable recurring payments to pay your invoices automatically with your Credit Card
                        <br><br>
                        <b-button
                          class="btn-sm"
                          variant="primary"
                          :disabled="spinnerEnabledAutoPayPal"
                          @click="enableCardSubscription"
                        >
                          <span v-if="spinnerEnabledAutoPayPal">
                            <b-spinner
                              small
                              type="grow"
                            />
                            Enabling...
                          </span>
                          <span v-else>
                            <credit-card-icon
                              size="1x"
                              class="custom-class"
                            />
                            Enable recurring payments w/ Credit Card
                          </span>
                        </b-button>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
            <!--            <b-row v-else-if="isPaypal && !enableEuplatescCard">-->
            <!--              <b-col>-->
            <!--                <br>-->
            <!--                <b-card-->
            <!--                  border-variant="primary"-->
            <!--                  header="RECURRING PAYMENTS"-->
            <!--                  header-bg-variant="primary"-->
            <!--                  header-text-variant="white"-->
            <!--                  align="center"-->
            <!--                >-->
            <!--                  <b-card-text><br>-->
            <!--                    <div v-if="isPaypal">-->
            <!--                      <b-button-->
            <!--                        class="btn-sm"-->
            <!--                        variant="primary"-->
            <!--                        :disabled="spinnerDisabledAutoPayPal"-->
            <!--                        @click="cancelPaypalSubscription"-->
            <!--                      >-->
            <!--                        <span v-if="spinnerDisabledAutoPayPal">-->
            <!--                          <b-spinner-->
            <!--                            small-->
            <!--                            type="grow"-->
            <!--                          />-->
            <!--                          Disabling...-->
            <!--                        </span>-->
            <!--                        <span v-else>-->
            <!--                          <credit-card-icon-->
            <!--                            size="1x"-->
            <!--                            class="custom-class"-->
            <!--                          />-->
            <!--                          Disable recurring payments w/ Paypal-->
            <!--                        </span>-->
            <!--                      </b-button>-->
            <!--                    </div>-->
            <!--                  </b-card-text>-->
            <!--                </b-card>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <!--            <b-row v-else-if="enablePaypalRecurring">-->
            <!--              <b-col>-->
            <!--                <div style="text-align:center;margin-top:20px">-->
            <!--                  <info-icon-->
            <!--                    size="1.5x"-->
            <!--                    class="custom-class"-->
            <!--                  />-->
            <!--                  Enable recurring payments to pay your invoices automatically with your Paypal-->
            <!--                  <br><br>-->
            <!--                  <b-button-->
            <!--                    class="btn-sm"-->
            <!--                    variant="primary"-->
            <!--                    :disabled="spinnerEnabledAutoPayPal"-->
            <!--                    @click="enablePaypalSubscription"-->
            <!--                  >-->
            <!--                    <span v-if="spinnerEnabledAutoPayPal">-->
            <!--                      <b-spinner-->
            <!--                        small-->
            <!--                        type="grow"-->
            <!--                      />-->
            <!--                      Enabling...-->
            <!--                    </span>-->
            <!--                    <span v-else>-->
            <!--                      <credit-card-icon-->
            <!--                        size="1x"-->
            <!--                        class="custom-class"-->
            <!--                      />-->
            <!--                      Enable recurring payments w/ Paypal skrrkssrr-->
            <!--                    </span>-->
            <!--                  </b-button>-->
            <!--                </div>-->
            <!--              </b-col>-->
            <!--            </b-row>-->
          </b-card-text>
        </b-card-actions>

        <b-card-actions
          v-if="!isAffiliate && isNextInvoice"
          title="Invoice Value"
          sub-title="Your next invoice value to pay"
          icon="DollarSignIcon"
          action-collapse
        >
          <b-card-text class="pt-md-1 pt-0">
            <b-row class="mb-1">
              <b-col
                md="4"
                sm="12"
              >
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    Package Price:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ invoiceContent.PackagePrice }} Euro
                  </h4>
                </div>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    No. of months:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ invoiceContent.NumOfMonths }}
                  </h4>
                </div>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    Subtotal value:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ invoiceContent.SubtotalValue }} Euro
                  </h4>
                </div>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    Discount value:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ invoiceContent.Discount }} Euro
                  </h4>
                </div>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    VAT {{ invoiceContent.Vat }} %:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ invoiceContent.AddedVat.toFixed(2) }} Euro
                  </h4>
                </div>
              </b-col>
              <b-col
                md="4"
                sm="12"
              >
                <div class="text-center mx-3 mt-1">
                  <p class="mb-0">
                    Invoice value:
                  </p>
                  <h4 class="font-weight-bolder">
                    {{ invoiceContent.InvoiceValue }} Euro
                  </h4>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-actions>

        <validation-observer ref="rules">
          <b-card-actions
            title="Customer Type"
            sub-title="Invoicing: Company or Individual"
            icon="UsersIcon"
            action-collapse
          >
            <b-card-text class="pt-md-1 pt-0">
              <b-row class="d-flex justify-content-center">
                <b-col
                  md="10"
                  sm="12"
                >
                  <div class="text-center mx-3 mt-1">

                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="coperson"
                      :options="['Company', 'Individual']"
                      name="radio-options-2"
                      @change="selectCompanyOrIndividual"
                    />
                    <hr>
                    <b-form-group
                      label="Website"
                      label-for="website"
                      label-cols="4"
                      :class="isPhone ? 'd-none' : ''"
                    >
                      <b-form-input
                        id="website"
                        v-model="website"
                        placeholder="Website"
                      />
                    </b-form-group>
                    <!-- PHONE!!!!!!!!!!!!!!!!!!!!!!!! -->
                    <b-form-group
                      label-for="website"
                      label-cols="0"
                      :class="isPhone ? '' : 'd-none'"
                    >
                      <h6>Website</h6>
                      <b-form-input
                        id="website"
                        v-model="website"
                        placeholder="Website"
                      />
                    </b-form-group>
                    <!-- PHONE!!!!!!!!!!!!!!!!!!!!!!!! -->
                    <div>
                      <b-form-group
                        v-if="companyDetails"
                        class="adjustments2"
                        label="Company Name *"
                        label-for="company"
                        label-cols="4"
                        :class="isPhone ? 'd-none' : ''"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="company"
                          rules="required"
                        >
                          <b-form-input
                            id="compnay"
                            v-model="company"
                            style="flex:max-content"
                            placeholder="Company Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- PHONE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
                      <b-form-group
                        v-if="companyDetails"
                        class="adjustments2"
                        label-for="company"
                        label-cols="0"
                        :class="isPhone ? '' : 'd-none'"
                      >
                        <h6>Company Name *</h6>
                        <validation-provider
                          #default="{ errors }"
                          name="company"
                          rules="required"
                        >
                          <b-form-input
                            id="compnay"
                            v-model="company"
                            style="flex:max-content"
                            placeholder="Company Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- PHONE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
                    </div>
                    <b-form-group
                      v-if="companyDetails"
                      label="VAT / Reg # "
                      label-for="vat"
                      label-cols="4"
                      :class="isPhone ? 'd-none' : ''"
                    >
                      <div
                        class="adjustments"
                        :style=" isPhone ? 'display:flex;gap:5px;flex-direction:column' : 'display:flex;'"
                        :class="isPhone ? 'd-none' : ''"
                      >
                        <!-- <b-form-input
                          v-model="countryInput"
                          :style="isPhone ? ' text-align: center; align-self:flex-start;' : 'width:55px; text-align: center;'"
                          :class="isEu ? '' : 'd-none'"
                          placeholder="N/A"
                          autocapitalize="characters"
                        /> -->
                        <b-form-input
                          id="vat"
                          v-model="vat"
                          placeholder="VAT / Reg #"
                          autocapitalize="characters"
                        />
                        <div
                          v-if="!isPhone"
                          style="display:flex;flex-direction:column;align-items: center;"
                          title="Autofill function is available only for companies registered in the EU."
                        >
                          <b-button
                            style="height:38px;"
                            :class="isEu ? 'btn btn-success btn-block' : 'd-none'"
                            :disabled="!vat"
                            @click="autoFill"
                          >
                            Autofill
                          </b-button>
                        </div>
                      </div>
                      <h6 style="margin-top: 5px;">
                        CC (country code) + VAT
                      </h6>
                    </b-form-group>
                    <!-- PHONE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
                    <b-form-group
                      v-if="companyDetails"
                      label-for="vat"
                      label-cols="4"
                      :class="isPhone ? '' : 'd-none'"
                    >
                      <h6 style="margin-top:10px;">
                        VAT / Reg #
                      </h6>
                      <div
                        class="adjustments"
                        :style=" isPhone ? 'display:flex;gap:5px;flex-direction:row' : 'display:flex;'"
                        :class="isPhone ? '' : ''"
                      >
                        <!-- <b-form-input
                          v-model="countryInput"
                          :style="isPhone ? ' text-align: center; align-self:flex-start;width:55px' : 'width:55px; text-align: center;'"
                          :class="isEu ? '' : 'd-none'"
                          placeholder="N/A"
                          autocapitalize="characters"
                        /> -->
                        <b-form-input
                          id="vat"
                          v-model="vat"
                          placeholder="VAT / Reg #"
                          autocapitalize="characters"
                        />
                        <div
                          v-if="!isPhone"
                          style="display:flex;flex-direction:column;align-items: center;"
                          title="Autofill function is available only for companies registered in the EU."
                        >
                          <b-button
                            style="height:38px;"
                            :class="isEu ? 'btn btn-success btn-block' : 'd-none'"
                            :disabled="!vat"
                            @click="autoFill"
                          >
                            Autofill
                          </b-button>
                        </div>
                      </div>
                    </b-form-group>
                    <!-- PHONE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
                    <div
                      v-if="isPhone"
                      style="display:flex;flex-direction:column;align-items: center;margin-bottom: 7px;"
                      title="Autofill function is available only for companies registered in the EU."
                      :class="isEu ? '' : 'd-none'"
                    >
                      <b-button
                        style="height:38px;width:161.41px;"
                        class="btn btn-block btn-success"
                        :disabled="!vat"
                        @click="autoFill"
                      >
                        Autofill
                      </b-button>
                      <span
                        style="font-size:10px;"
                        class="text-danger"
                      >
                        (Function only available in EU countries)
                      </span>
                    </div>
                    <b-button
                      class="mb-1"
                      variant="primary"
                      :disabled="spinnerUpdateAccount"
                      @click="updateForm"
                    >
                      <span v-if="spinnerUpdateAccount">
                        <b-spinner
                          small
                          type="grow"
                        />
                        Updating...
                      </span>
                      <span v-else>Save all changes</span>
                    </b-button>

                    <!-- DPA -->
                    <b-row
                      :class="[
                        !isPhone ? 'd-flex' : '',
                        'justify-content-around'
                      ]"
                      :style="isPhone ? 'display: grid;' : ''"
                    >
                      <div :class="!isPhone ? 'customMarginAgreement' : ''">
                        <b-row class="d-flex justify-content-center">
                          <b-form-group
                            v-if="customer.hasSignedDPA"
                            label="Select the DPA to download"
                            style="font-size: 16px; font-weight: 600;"
                          >
                            <b-row class="d-flex justify-content-center">
                              <b-form-select
                                v-model="templateDPA"
                                :options="allDPA"
                                @change="changeDownloadDPA"
                              />
                            </b-row>
                          </b-form-group>
                        </b-row>
                        <button
                          v-if="customer.hasSignedDPA"
                          type="button"
                          class="btn btn-success"
                          :style="isPhone ? 'padding:8px;' : ''"
                          :disabled="downloading1"
                          @click="downloadDPA('dpa')"
                        ><span v-if="downloading1">
                           <b-spinner
                             small
                             type="grow"
                           />
                           Downloading...
                         </span>
                          <span v-else>Download DPA Signed Copy</span>
                        </button>
                        <button
                          v-else
                          type="button"
                          class="btn btn-warning"
                          :style="isPhone ? 'padding:8px;' : ''"
                          @click="openDPA"
                        >
                          Generate DPA Signed Copy
                        </button>
                      </div>

                      <div
                        v-if="isPhone"
                        class="mb-1"
                      />

                      <!-- CLIENT AGREEMENT -->
                      <div :class="!isPhone ? 'customMarginAgreement' : ''">
                        <b-row class="d-flex justify-content-center">
                          <b-form-group
                            v-if="customer.hasSignedClientAgreement"
                            label="Select the Client Agreement to download"
                            style="font-size: 16px; font-weight: 600;"
                          >
                            <b-row class="d-flex justify-content-center">
                              <b-form-select
                                v-model="templateClientAgreement"
                                :options="allClientAgreement"
                                @change="changeDownloadClientAgreement"
                              />
                            </b-row>
                          </b-form-group>
                        </b-row>
                        <button
                          v-if="customer.hasSignedClientAgreement"
                          type="button"
                          class="btn btn-success"
                          :style="isPhone ? 'padding:8px;' : ''"
                          :disabled="downloading2"
                          @click="downloadDPA('client_agreement')"
                        ><span v-if="downloading2">
                           <b-spinner
                             small
                             type="grow"
                           />
                           Downloading...
                         </span>
                          <span v-else>Download Client Agreement Signed Copy</span>
                        </button>
                        <button
                          v-else
                          type="button"
                          class="btn btn-warning"
                          :style="isPhone ? 'padding:8px;' : ''"
                          @click="openClientAgreement"
                        >
                          Generate Client Agreement Signed Copy
                        </button>
                      </div>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>

          </b-card-actions>
          <b-card-actions
            title="Billing Address"
            sub-title="The address on the invoice"
            icon="MapPinIcon"
            action-collapse
          >
            <b-card-text class="pt-md-1 pt-0">
              <b-row class="mb-1">
                <b-col
                  md="6"
                  sm="12"
                >

                  <b-form-group
                    label="Country *"
                    label-for="country"
                    label-cols-sm="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="country"
                      rules="required"
                    >
                      <b-form-select
                        id="country"
                        v-model="country"
                        style="width: 100%!important;"
                        :options="countryOption"
                        @change="getRegion"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="City *"
                    label-for="city"
                    label-cols-sm="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="city"
                      rules="required"
                    >
                      <b-form-input
                        id="city"
                        v-model="city"
                        placeholder="City"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="!isPhone"
                    label="Email Invoices to"
                    label-for="access_email"
                    label-cols-sm="3"
                  >
                    <b-form-input
                      id="access_email"
                      v-model="access_email"
                      placeholder="(Optional)"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >

                  <b-form-group
                    label="Address 1 *"
                    label-for="address1"
                    label-cols-sm="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="title"
                      rules="required"
                    >
                      <b-form-input
                        id="address1"
                        v-model="address1"
                        placeholder="Address 1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Postcode *"
                    label-for="postcode"
                    label-cols-sm="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="postcode"
                      rules="required"
                    >
                      <b-form-input
                        id="postcode"
                        v-model="postcode"
                        placeholder="Postcode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Address 2"
                    label-for="address2"
                    label-cols-sm="3"
                  >
                    <b-form-input
                      id="address2"
                      v-model="address2"
                      placeholder="Address 2"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="isPhone"
                    label="Email Invoices to"
                    label-for="access_email"
                    label-cols-sm="3"
                  >
                    <b-form-input
                      id="access_email"
                      v-model="access_email"
                      placeholder="(Optional)"
                    />
                  </b-form-group>
                </b-col>
                <b-button
                  variant="primary"
                  style="margin: auto;"
                  :disabled="spinnerUpdateAccount"
                  @click="updateForm"
                >
                  <span v-if="spinnerUpdateAccount">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Updating...
                  </span>
                  <span v-else>Save all changes</span>
                </b-button>
              </b-row>
            </b-card-text>
          </b-card-actions>
        </validation-observer>

        <b-card-actions
          title="Save changes"
          icon="ChevronsRightIcon"
          sub-title="Click the update button (if you wish to update your changes made above)."
          action-collapse
        >
          <b-card-text>
            <b-row>
              <b-col class="text-center mt-2">
                <p v-if="isBillRelevant">
                  <info-icon
                    size="1.5x"
                    class="custom-class"
                  />
                  Any billing-relevant changes that you make to your account details
                  will only appear on your next invoice (issued on {{ next_invoice_start }}).
                </p>
                <p style="color:red">
                  <arrow-right-circle-icon
                    size="1.5x"
                    class="custom-class"
                  />
                  * required information (mandatory).
                </p>
              </b-col>
              <b-col
                cols="12"
                class="text-center mt-1 mb-2"
              >
                <b-button
                  variant="primary"
                  :disabled="spinnerUpdateAccount"
                  @click="updateForm"
                >
                  <span v-if="spinnerUpdateAccount">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Updating...
                  </span>
                  <span v-else>Save all changes</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-actions>

        <b-card-actions
          v-if="isCancelSubscription"
          title="Cancel Subscription"
          icon="XOctagonIcon"
          action-collapse
        >
          <b-card-text>
            <b-row>
              <b-col class="text-center mt-2">
                <h4>You can cancel your subscription, at any time,in accordance with the <a
                  href="https://www.trafficmanager.com/billing"
                  target="_blank"
                >Billing & Refund Policy</a>.</h4>
              </b-col>
              <b-col
                cols="12"
                class="text-center mt-1 mb-2"
              >
                <b-button
                  variant="primary"
                  :disabled="spinnerCancelSubs"
                  @click="cancelSubcription"
                >
                  <span v-if="spinnerCancelSubs">
                    <b-spinner
                      small
                      type="grow"
                    />
                    Canceling...
                  </span>
                  <span v-else>Cancel Subscription</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-actions>

        <b-modal
          id="save-dpa-agreement"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          hide-header-close
          size="xl"
          title="Please accept the DPA Agreement."
        >
          <div class="text-center">
            <DpaAgreementVue
              :customer="customer"
              @generatedPDF="checkGeneratedPDF"
              @userAgreeToDPA="setDPAAgreement"
            />
            <div>
              <b-alert
                show
                dismissible
                variant="warning"
              >
                Every time you change your name at the company, you will have to sign a new DPA.
              </b-alert>
            </div>
            <b-button
              style="margin:5px 0px 0px 0px"
              variant="primary"
              @click="closeDPA"
            >Close</b-button>
          </div>
        </b-modal>

        <b-modal
          id="save-client-agreement"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          hide-header-close
          size="xl"
          title="Please accept the Client Agreement."
        >
          <div class="text-center">
            <ClientAgreementVue
              :customer="customer"
              @generatedPDF="checkGeneratedClientAgreementPDF"
              @userAgreeToClientAgreement="setClientAgreement"
            />
            <div>
              <b-alert
                show
                dismissible
                variant="warning"
              >
                Every time you change your name at the company, you will have to sign a new DPA.
              </b-alert>
            </div>
            <b-button
              style="margin:5px 0px 0px 0px"
              variant="primary"
              @click="closeClientAgreement"
            >Close</b-button>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BRow, BCol, BCardText, BButton, BFormSelect, BModal, BAlert,
  BFormRadioGroup, BFormGroup, BFormInput, BCard, BSpinner, VBTooltip
} from 'bootstrap-vue'
import axios from '@/libs/axios' // eslint-disable-line
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import {
  InfoIcon, ArrowRightCircleIcon, PlusIcon, XIcon, BellOffIcon, CheckSquareIcon, CreditCardIcon, CheckIcon,
} from 'vue-feather-icons'
import BCardActions from '../layouts/components/BCardActions.vue'
import DpaAgreementVue from '../views/user_dpa_agreements/UserDpaAgreement.vue'
import ClientAgreementVue from '../views/user_dpa_agreements/UserClientAgreement.vue'
import VueCookies from 'vue-cookies';

const jsonpAdapter = require('axios-jsonp') // eslint-disable-line

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BModal,
    BAlert,
    VBTooltip,
    BCardText,
    BButton,
    BFormSelect,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    InfoIcon,
    ArrowRightCircleIcon,
    BCard,
    PlusIcon,
    XIcon,
    BellOffIcon,
    CheckSquareIcon,
    BSpinner,
    CreditCardIcon,
    CheckIcon,
    DpaAgreementVue,
    ClientAgreementVue,
  },
  emits: ['generatedPDF'],
  data() {
    return {
      isInit: true,
      user: {},
      billing: [],
      settings: [],

      package: {},
      packages: [],
      packageList: [],
      curPackage: '',
      curPackageType: '',
      includeValue: '',

      monthsOption: [{ text: 1, value: 1 }, { text: 3, value: 3 }, { text: 6, value: 6 }, { text: 12, value: 12 }],
      payMonths: 1,

      invoiceContent:
      {
        PackagePrice: 0,
        NumOfMonths: 0,
        SubtotalValue: 0,
        Vat: 0,
        InvoiceValue: 0,
        Discount: 0,
        Period: '',
        AddedVat: 0,
      },

      coperson: 'Individual',
      website: '',
      company: '',
      vat: '',
      companyDetails: false,

      address1: '',
      address2: '',
      postcode: '',
      access_email: '',
      access_email_same: '',
      city: '',
      country: '',
      region: '',
      countryOption: [],
      regionOption: [],

      // is cancel subscription?
      isCancelSubscription: false,

      next_invoice_start: '',

      // any bill relevant
      isBillRelevant: false,

      // renew subscription
      isRenewSubscription: false,

      // affiliate
      isAffiliate: false,

      // constant variable from object model
      auto_payment_mode_euplatesc_card: 0,
      auto_payment_mode_paypal: 0,
      account_type_casino: 0,

      // check if Casino
      isCasino: false,

      // recurring
      isRecurring: false,

      // next invoice
      isNextInvoice: false,

      // payment
      isPaypal: false,
      isEuplatesc: false,
      euplatescCards: [],
      selectedCard: '',
      enableEuplatescCard: false,
      enablePaypalRecurring: false,
      paypalPlanId: '',

      // is not paid properma
      notPaidProforma: false,

      // others
      creditNotes: 0,
      cannotChangePackage: false,
      spinnerRenewSubs: false,
      spinnerCancelSubs: false,
      spinnerUpdateAccount: false,
      spinnerAddCC: false,
      spinnerRemoveCC: false,
      spinnerDisabledAutoPayCC: false,
      spinnerEnabledAutoPayCC: false,
      spinnerDisabledAutoPayPal: false,
      spinnerEnabledAutoPayPal: false,
      isNewPackage: false,
      spinnerDefaultCC: false,

      packageType: 0,
      showSetDefaultBtn: false,

      customer: {
        uid: 0,
        name: 'Customer Name',
        company: 'Customer Company',
        title: 'Customer Title',
        position: 'Customer Position',
        address: 'Customer Address',
        signature: '',
        hasSignedDPA: false,
        hasSignedClientAgreement: false,
        dateSignedClientAgreement: '',
        dateSignedDPA: '',
      },
      agreeDPA: false,
      agreeClientAgreement: false,
      allDPA: [],
      allClientAgreement: [],
      templateDPA: 1,
      templateClientAgreement: 1,
      downloading1: false,
      downloading2: false,
      inputNA: '',
      // countryInput: '',
    }
  },
  computed: {
    isPhone: {
      get() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true;
        return false;
      }
    },
    isEu : {
        get(){
            let country = this.user.country
            // make country string value to uppercase
            if(country){
            country = country.toUpperCase()
            const eu_countries = [
                'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI',
                'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU',
                'MT', 'NL', 'PL', 'PT', 'SK', 'SI', 'ES', 'SE'
            ]
            return eu_countries.includes(country)
          }
        }
    },
    formatVat : {
      get(){
        // if(this.isEu){
        //   return this.vat.match(/[0-9]/gmi).join("")
        // } else {
        //   return this.vat
        // }
        return this.vat
      }
    },

    countryInput : {
      get(){
        if(this.isEu){
          if(this.billing.vat === "0.00"){
            return this.country
          } else {
            return this.inputNA
          }
        }
      },
      set(value) {
        if(value) return;
      }
    },

  },
  created() {
    this.fillCustomerInfo()
  },
  mounted() {
    this.getAccountInfo()
    this.getAccessEmail()
  },
  methods: {
    async autoFill() {
      if (this.vat) {
            let vat_number = this.vat
            vat_number = vat_number.trim()
            vat_number = vat_number.replace(/ /g, '')
            vat_number = vat_number.replace('-', '')
            this.vatno = vat_number
            // if(this.isEu) {
            //   // if(this.billing.vat === "0.00"){
            //     vat_number = this.countryInput + vat_number
            //   // }
            // }

            const accessKey = 'c14cce7738efb19a9728292b361a0685' // eslint-disable-line

            let geturl = 'https://apilayer.net/api/validate' // eslint-disable-line
            geturl = `${geturl}?vat_number=${vat_number}` // eslint-disable-line
            geturl = `${geturl}&access_key=${accessKey}` // eslint-disable-line

            const resVat = axios({
              url: geturl,
              adapter: jsonpAdapter,
              method: 'get',
            })

            const resVatData = await resVat.then(result => result).catch(error => error)
            const resData = resVatData.data
            // If vat is not valid give a warning message
            if(resData.valid === false) {
              this.$bvToast.toast('VAT number is not valid or is not registered in the European Union VIES system', {
                title: 'Warning',
                variant: 'warning',
                solid: true,
              })
            } else {
              this.company = resData.company_name
              if(resData.company_address) this.address1= resData.company_address.replace(/\n/g, ", ")
              this.country = resData.country_code
              this.region = resData.country_code
              this.countryInput = resData.country_code

              // let firstTwoChars = this.vat.substring(0, 2);
              // if (firstTwoChars.match(/[a-zA-Z]/)) {
              //   this.vat = this.vat.slice(2)
              // }

              // Notify the user the data is filled
              this.$bvToast.toast('VAT number is valid, autofill success!', {
                title: 'Success',
                variant: 'success',
                solid: true,
              })
            }
          }
    },

    //get the customer infos from the dpa table
    async fillCustomerInfo()
    {
      const checkEmptyData = (data) => {
           return (data?.length < 2 || !data || data == " ") ? ('') : (data);
       };

      const user = this.$store.state.auth.userData
      this.customer.uid = user.uid

      const response = await axios.get(`/api/dpa/get_last_signed_dpa/${user.uid}/dpa`)

      if(response.data.signed === true) {
        const info = response.data.info;
        this.customer.name = info.full_name;
        this.customer.company = info.company;
        this.customer.title = info.title;
        this.customer.address = info.address;
        this.customer.signature = info.signature;
        this.customer.dateSignedDPA = info.created_at;
        this.customer.hasSignedDPA = true;
        this.agreeDPA = true;
      } else {
        this.customer.name = `${user.forename} ${user.surname}`;
        this.customer.company = checkEmptyData(user.company);
        this.customer.title = checkEmptyData(user.title);
        this.customer.address = `${user.address1} ${user.city}, ${user.region} ${user.country}`;
        this.customer.hasSignedDPA = false;
        this.agreeDPA = false;
      }

      if (this.customer.company != user.company) {
        this.customer.name = `${user.forename} ${user.surname}`;
        this.customer.company = checkEmptyData(user.company);
        this.customer.title = checkEmptyData(user.title);
        this.customer.address = `${user.address1} ${user.city}, ${user.region} ${user.country}`;
        this.customer.hasSignedDPA = false;
        this.agreeDPA = false;
      }

      const response2 = await axios.get(`/api/dpa/get_last_signed_dpa/${user.uid}/client_agreement`)
      if (response2.data.signed === true) {
        const info = response2.data.info;
        this.customer.hasSignedClientAgreement = true;
        this.agreeClientAgreement = true;
        this.customer.dateSignedClientAgreement = response2.data.info.created_at;

        if (info.company != user.company) {
          this.customer.name = `${user.forename} ${user.surname}`;
          this.customer.company = checkEmptyData(user.company);
          this.customer.title = checkEmptyData(user.title);
          this.customer.address = `${user.address1} ${user.city}, ${user.region} ${user.country}`;
          this.customer.hasSignedClientAgreement = false;
          this.agreeClientAgreement = false;
        }

        this.getCustomerAllClientAgreement(user.uid);
      } else {
        this.customer.hasSignedClientAgreement = false;
        this.agreeClientAgreement = false;
      }

      if(this.customer.dateSignedDPA) this.getCustomerAllDPA(user.uid);
    },

    async getCustomerAllDPA(uid)
    {
      const response = await axios.get(`/api/dpa/get_user_all_dpa/${uid}`)

      if (response) {
        this.allDPA = [];
        response.data.filter((dpa) => dpa.type === "dpa").forEach((dpa, i) => {
            const dpaDate = new Date(dpa.created_at).toLocaleDateString("en-GB",
              { year: 'numeric', month: 'long', day: 'numeric' }
            );
            const obj = {
              ...dpa,
              text: `${dpa.company.replaceAll(" ", "_")}.pdf (${dpaDate})`,
              value: i + 1
            }
            this.allDPA.push(obj)
        });
        this.templateDPA = 1;
      }
      else {
        this.agreeDPA = false;
        this.customer.hasSignedDPA = false;
      }
    },

    async getCustomerAllClientAgreement(uid)
    {
      const response = await axios.get(`/api/dpa/get_user_all_dpa/${uid}`)

      if (response) {
        this.allClientAgreement = [];
        response.data.filter((dpa) => dpa.type === "client_agreement").forEach((dpa, i) => {
            const dpaDate = new Date(dpa.created_at).toLocaleDateString("en-GB",
              { year: 'numeric', month: 'long', day: 'numeric' }
            );
            const obj = {
              ...dpa,
              text: `${dpa.company.replaceAll(" ", "_")}.pdf (${dpaDate})`,
              value: i + 1
            }
            this.allClientAgreement.push(obj)
        });
        this.templateClientAgreement = 1;
      }
      else {
        this.agreeClientAgreement = false;
        this.customer.hasSignedClientAgreement = false;
      }
    },

    async downloadDPA(type) {
        this.downloading1 = type === 'dpa';
        this.downloading2 = type === 'client_agreement';

        const payload = {
          name: this.customer.name,
          company: this.customer.company,
          date: type === 'dpa' ? this.customer.dateSignedDPA : this.customer.dateSignedClientAgreement,
          type: type ?? 'dpa',
        };

        await axios.post(`/api/dpa/download`, payload, { responseType: 'blob' }).then(res => {
          if (!res || !res?.data) {
              this.notify({
                text: 'The PDF has not yet been generated by the server, please try again later !',
                variant: 'danger',
              });
            this.downloading1 = false;
            this.downloading2 = false;
            return;
          }
          const fileName = payload.type === 'client_agreement' ? 'Client_Agreement' : 'DPA_Agreement';
          const link = document.createElement('a');
          const pdf_url = URL.createObjectURL(res.data);
          link.setAttribute('href', pdf_url);
          link.setAttribute('download', `${this.customer.name.replaceAll(" ", "_")}_${fileName}.pdf`);
          link.style.visibility = 'hidden';
          link.id = 'downloadPDF';
          document.body.appendChild(link);
          document.getElementById(link.id).click();

          const element = document.getElementById(link.id);
          element.remove();
        });
        this.downloading1 = false;
        this.downloading2 = false;
    },

    changeDownloadDPA() {
      const idDPA = this.templateDPA - 1;
      this.customer.name = this.allDPA[idDPA].full_name;
      this.customer.company = this.allDPA[idDPA].company;
      this.customer.title = this.allDPA[idDPA].title;
      this.customer.address = this.allDPA[idDPA].address;
      this.customer.signature = this.allDPA[idDPA].signature;
      this.customer.dateSignedDPA = this.allDPA[idDPA].created_at;
    },

    changeDownloadClientAgreement() {
      const idDPA = this.templateClientAgreement - 1;
      this.customer.name = this.allClientAgreement[idDPA].full_name;
      this.customer.company = this.allClientAgreement[idDPA].company;
      this.customer.title = this.allClientAgreement[idDPA].title;
      this.customer.address = this.allClientAgreement[idDPA].address;
      this.customer.signature = this.allClientAgreement[idDPA].signature;
      this.customer.dateSignedClientAgreement = this.allClientAgreement[idDPA].created_at;
    },

    openDPA() {
      if(this.agreeDPA) {
        this.notify({
          text: "Wait for the PDF to be generated .",
          variant: 'info',
        })
        return
      }
      this.$bvModal.show('save-dpa-agreement')
    },

    openClientAgreement() {
      if(this.agreeClientAgreement) {
        this.notify({
          text: "Wait for the PDF to be generated .",
          variant: 'info',
        })
        return
      }
      this.$bvModal.show('save-client-agreement')
    },

    setDPAAgreement(value) {
      this.agreeDPA = value;
    },

    setClientAgreement(value) {
      this.agreeClientAgreement= value;
    },

    closeDPA() {
      if(!this.agreeDPA) return this.$bvModal.hide('save-dpa-agreement')

      const dpaModal = document.getElementById('save-dpa-agreement');
      dpaModal.style.visibility = "hidden";
      dpaModal.style.opacity = "0";
      document.body.style.overflow = "visible";

      const dpaBackdrop = document.getElementById('save-dpa-agreement___BV_modal_backdrop_');
      dpaBackdrop.remove();
    },

    closeClientAgreement() {
      if(!this.agreeClientAgreement) return this.$bvModal.hide('save-client-agreement')

      const clientAgreementModal = document.getElementById('save-client-agreement');
      clientAgreementModal.style.visibility = "hidden";
      clientAgreementModal.style.opacity = "0";
      document.body.style.overflow = "visible";

      const clientAgreementBackdrop = document.getElementById('save-client-agreement___BV_modal_backdrop_');
      clientAgreementBackdrop.remove();
    },

    checkGeneratedPDF(value) {
      if (value) {
        this.agreeDPA = value;
        this.customer.hasSignedDPA = value;
        this.getCustomerAllDPA(this.customer.uid);
      }
      else {
        this.$bvToast.toast('The PDF could not be generated successfully! Please create a ticket !', {
          title: 'PDF Generated',
          variant: 'danger',
          solid: true,
          autoHideDelay: 10000,
        });
      }
    },

    checkGeneratedClientAgreementPDF(value) {
      if (value) {
        this.agreeClientAgreement = value;
        this.customer.hasSignedClientAgreement = value;
        this.getCustomerAllClientAgreement(this.customer.uid);
      }
      else {
        this.$bvToast.toast('The PDF could not be generated successfully! Please create a ticket !', {
          title: 'PDF Generated',
          variant: 'danger',
          solid: true,
          autoHideDelay: 10000,
        });
      }
    },

    messageReload() {
      window.location.reload()
    },

    async addAccessEmail () {
      const response = await axios.post('/api/account/email_to_another_user', {
        access_email: this.access_email
      })
      if (response.status === 200) {
        this.notify({
          text: 'Email added successfully',
          variant: 'success',
        })
      } else {
        this.notify({
          text: 'Error adding email',
          variant: 'error',
        })
      }
    },

    /* eslint-enable */
    checkNewPackage() {
      this.packageType = Number(this.package.uid)
      if ((this.packageType >= 7003 && this.packageType <= 7006) || this.packageType === 5003 || this.packageType === 6003) this.isNewPackage = true
    },
    async getAccountInfo() {
      // redirect if user is limited
      const limited = VueCookies.get('limited')
      if (limited) {
        this.$router.push({ name: 'home' })
      }
      const response = await axios.get('/api/account/get_account_info')
      if (response.status === 200) {
        const { data } = response
        this.user = data.user
        this.billing = data.billing[0] // eslint-disable-line
        this.settings = data.settings[0] // eslint-disable-line
        this.countryInput = data.user.region
        const userStatus = Number(this.user.user_status)

        // const affiliate = Number(this.user.is_affiliate)
        const affiliate = this.user.is_affiliate

        // current package selected by the user
        this.package = data.package
        this.curPackage = data.package.uid

        // available packages from db
        this.packages = data.packages

        // extracted package name for b-select
        this.packages.forEach(obj => {
          // bug fix
          if (obj.name === this.package.name) this.curPackage = obj.uid

          const map = {
            text: obj.name,
            value: obj.uid,
          }
          this.packageList.push(map)
        })

        const normal_acc_pack = [7003, 7004, 7005, 7006]
        const casino_acc_pack = [5003, 6003]
        // if current package in normal account package
        if (normal_acc_pack.includes(Number(this.curPackage))) {
          // push casino packages to packageList
          this.packageList.push({
            text: 'AGENCY - CASINO',
            value: 5003,
          })
          this.packageList.push({
            text: 'ENTERPRISE - CASINO',
            value: 6003,
          })
        }
        // if current package in casino account package
        if (casino_acc_pack.includes(Number(this.curPackage))) {
          // push normal packages to packageList
          // this.packageList.push({
          //   text: 'STARTER - NORMAL',
          //   value: 7003,
          // })
          this.packageList.push({
            text: 'PROFESSIONAL - NORMAL',
            value: 7004,
          })
          this.packageList.push({
            text: 'AGENCY - NORMAL',
            value: 7005,
          })
          this.packageList.push({
            text: 'ENTERPRISE - NORMAL',
            value: 7006,
          })
        }
        // package type
        this.curPackageType = this.billing.paymode
        this.setIncludedValue()

        // pay month
        this.payMonths = Number(this.billing.pay_months)

        // get the calculated value of invoice if applicable
        // this.changePayMonths()
        this.invoiceInitalValue(data)

        // company type
        this.website = this.settings.website
        this.company = this.user.company
        this.vat = this.user.regno
        if (this.user.vatno) this.vat = this.user.vatno

        if (this.company) {
          this.coperson = 'Company'
          this.companyDetails = true
        }

        // address
        this.countryOption = this.formatData(data.countries)
        this.country = this.billing.bill_country

        this.regionOption = this.formatData(data.regions, true)
        this.region = this.billing.bill_region

        this.address1 = this.billing.bill_address1
        this.address2 = this.billing.bill_address2
        this.postcode = this.billing.bill_postcode
        this.city = this.billing.bill_city

        this.countries = data.countries

        // is cancel subscription?
        const isCancelArray = [4, 5, 7, 8, 9]
        if (isCancelArray.includes(this.user.user_status)) this.isCancelSubscription = true
        if (this.user.sub_start || data.grace) this.isBillRelevant = true

        this.next_invoice_start = data.next_invoice_start

        // renew subscription
        if (userStatus === 6) this.isRenewSubscription = true

        if (affiliate === 1) this.isAffiliate = true

        // constant variable
        this.auto_payment_mode_euplatesc_card = data.auto_payment_mode_euplatesc_card
        this.auto_payment_mode_paypal = data.auto_payment_mode_paypal
        this.account_type_casino = data.account_type_casino

        // payments
        if (this.settings.auto_payment_mode === data.auto_payment_mode_euplatesc_card) this.isEuplatesc = true
        if (this.settings.auto_payment_mode === data.auto_payment_mode_paypal) this.isPaypal = true

        this.enablePaypalRecurring = this.checkEnablePaypal(data)

        if (data.euplatescCard) this.enableEuplatescCard = true // ready for recurring with EuplatescCard

        // check casino
        if (this.account_type_casino === this.user.account_type) this.isCasino = true

        // next invoice
        if (this.user.user_status >= 4) this.isNextInvoice = true

        // check recurring
        // if (this.user.user_status >= 5 && !data.hasDiscount) this.isRecurring = true
        if (this.settings.auto_payment_mode > 0) this.isRecurring = true

        // cards option
        this.selectedCard = this.settings.euplatesc_card_id

        const selectedCardIDSettings = Number(this.settings.euplatesc_card_id)

        if (typeof this.user.euplatescCards !== 'undefined') {
          this.user.euplatescCards.forEach(obj => {
            let text = ''
            if (Number(obj.id) === selectedCardIDSettings) {
              text = `${obj.card_type}*****${obj.mcard
              } (Expires ${obj.card_exp.substr(0, 2)}/${
                obj.card_exp.substr(3)} - default)`
            } else {
              text = `${obj.card_type}*****${obj.mcard
              } (Expires ${obj.card_exp.substr(0, 2)}/${
                obj.card_exp.substr(3)})`
            }

            const map = {
              text,
              value: obj.id,
            }
            this.euplatescCards.push(map)
          })
        }

        // others
        this.creditNotes = data.creditNotes
        this.paypalPlanId = data.paypalPlanId

        this.checkNewPackage()
        const resultShowSetDefaultBtn = this.setDefaultBtn()

        if (resultShowSetDefaultBtn) {
          if (this.euplatescCards.length === 1) this.showSetDefaultBtn = false
          if (this.euplatescCards.length > 1) this.showSetDefaultBtn = true
        }
        // // if he has vat 0 than this.vat will only be numbers
        // if (this.billing.vat === '0.00' && this.isEu) {
        //   // remove the first two letters
        //   this.vat = this.vat.substr(2)
        // }
      }
    },

    setDefaultBtn() {
      this.showSetDefaultBtn = false
      if (this.settings.euplatesc_card_id === this.selectedCard) return false
      this.showSetDefaultBtn = true
      return true
    },

    checkEnablePaypal(data) {
      let enablePaypalRecurring = false
      if (data.user.country === 'RO') return enablePaypalRecurring
      if (Number(data.settings[0].auto_payment_mode) === 2) return enablePaypalRecurring // credit card recurring, hence return false
      if (data.paypalPlanId && data.paypalStartTime) enablePaypalRecurring = true
      return enablePaypalRecurring
    },

    invoiceInitalValue(data) {
      const billing = data.billing[0] // eslint-disable-line
      const curpackage = data.package

      let package_price = curpackage.price1
      let invoiceVal = (package_price * data.invoice_qty - data.invoice_discount) * (1 + billing.vat / 100)
      if (this.billing.pay_months === 3) {
        package_price = curpackage.price2
        invoiceVal = (package_price * data.invoice_qty - data.invoice_discount * data.invoice_qty) * (1 + billing.vat / 100)
      } else if (this.billing.pay_months === 6) {
        package_price = curpackage.price3
        invoiceVal = (package_price * data.invoice_qty - data.invoice_discount * data.invoice_qty) * (1 + billing.vat / 100)
      } else if (this.billing.pay_months === 12) {
        package_price = curpackage.price4
        invoiceVal = (package_price * data.invoice_qty - data.invoice_discount * data.invoice_qty) * (1 + billing.vat / 100)
      }

      this.invoiceContent.PackagePrice = package_price
      this.invoiceContent.NumOfMonths = data.invoice_qty.toLocaleString()
      this.invoiceContent.SubtotalValue = (package_price * data.invoice_qty).toLocaleString()
      this.invoiceContent.Vat = billing.vat.toLocaleString()
      this.invoiceContent.AddedVat = (billing.vat / 100) * (package_price * data.invoice_qty)
      this.invoiceContent.Discount = data.invoice_discount.toLocaleString()
      this.invoiceContent.InvoiceValue = invoiceVal.toLocaleString()
      this.invoiceContent.Period = `${data.invoice_start} - ${data.invoice_end}`
    },

    async changePayMonths() {
      const uri = `/api/invoices/invoice_calculate/${this.curPackage}/${this.payMonths}`
      const response = await axios.get(uri)
      if (response.status === 200) {
        const { data } = response
        this.invoiceContent.PackagePrice = data.package_price
        this.invoiceContent.NumOfMonths = data.invoice_qty
        this.invoiceContent.SubtotalValue = data.subtotal
        this.invoiceContent.Vat = data.vat
        this.invoiceContent.InvoiceValue = data.invoice_value
        this.invoiceContent.Discount = data.discount
        this.invoiceContent.Period = data.invoice_period
        this.invoiceContent.AddedVat = (data.vat / 100) * (data.subtotal)
      }
      this.isInit = false
    },

    selectPackage() {
      // eslint-disable-next-line eqeqeq
      if (this.curPackage == 7003) {
        this.notify({
          text: 'This package is no longer available',
          variant: 'danger',
        })
        this.curPackage = this.package.uid
        return
      }
      if (this.curPackage > this.package.uid
          && this.creditNotes >= 1
          && this.user.user_status >= 4) {
        this.cannotChangePackage = true
        this.packages.forEach(obj => {
          if (obj.name === this.package.name) { this.curPackage = obj.uid }
        })
      } else if (this.curPackage < this.package.uid
          && this.creditNotes >= 1
          && this.user.user_status >= 4) {
        this.cannotChangePackage = true
        this.packages.forEach(obj => {
          if (obj.name === this.package.name) { this.curPackage = obj.uid }
        })
      } else {
        this.isInit = false
        this.setIncludedValue()
        this.changePayMonths()
      }
      this.packageType = Number(this.curPackage)
    },

    selectPackageType() {
      this.setIncludedValue()
    },

    setIncludedValue() {
      if (this.isInit) {
        if (this.curPackageType === 'conversions') this.includeValue = this.package.conversions
        if (this.curPackageType === 'clicks') this.includeValue = this.package.clicks
      } else {
        const index = this.getPackageIndex()
        const curSelectedPackage = this.packages[index]
        if (this.curPackageType === 'conversions') this.includeValue = curSelectedPackage.conversions
        if (this.curPackageType === 'clicks') this.includeValue = curSelectedPackage.clicks
      }
    },

    getPackageIndex() {
      let index = 0
      this.packages.forEach((obj, i) => {
        if (obj.uid === this.curPackage) index = i
      })
      return index
    },

    selectCompanyOrIndividual() {
      const selected = this.coperson.toLowerCase()
      if (selected === 'company') {
        this.companyDetails = true
      } else {
        this.companyDetails = false
      }
    },

    async getRegion() {
      // get region through country ID
      const payload = {
        country: this.country,
      }

      const uri = '/api/account/getregions'
      const response = await axios.post(uri, payload)
      if (response.status === 200) {
        this.regionOption = this.formatData(response.data, true)
        this.region = this.regionOption[0].value
      }

      this.updateForm()
    },

    async getAccessEmail() {
      const response = await axios.get('/api/account/get_access_email')
      if (!response) return
      if (response.status === 200) {
        this.access_email = response.data.access_email
        this.access_email_same = response.data.access_email
      }
    },

    updateForm() {
      this.$refs.rules.validate().then(async success => { // eslint-disable-line
        if (success) {
          // this.spinnerUpdateAccount = true

          const payload = {
            coperson: this.coperson.toLowerCase(),

            website: this.website?.length === 0 || this.website === ' ' ? 'http://' : this.website,
            company: this.company,
            vatno: this.vat,

            package_select: this.curPackage,
            paymode: this.curPackageType,
            pay_months: this.payMonths,

            bill_country: this.country,
            bill_region: this.region,
            bill_city: this.city,
            access_email: this.access_email,
            bill_postcode: this.postcode,
            bill_address1: this.address1,
            bill_address2: this.address2,
            vat_checked: false,
          }

          if (this.cannotChangePackage) {
            payload.package_select = this.package.uid
          }

          if (payload.vatno) {
            let vat_number = payload.vatno
            if (this.isEu) {
              // if (this.billing.vat === '0.00') {
              //   const checkCodeVAT = (code, VAT) => {
              //     const exec = VAT.match(/^[a-zA-Z]{2}/gi)

              //     if (!exec) return false

              //     return (exec[0].toUpperCase() === code.toUpperCase())
              //   }
              //   if (checkCodeVAT(this.countryInput, vat_number)) {
              //     vat_number = payload.vatno
              //   } else {
              //     vat_number = this.countryInput + payload.vatno
              //   }
              // }
              vat_number = payload.vatno
            }
            vat_number.trim()
            vat_number = vat_number.replace(/ /g, '')
            vat_number = vat_number.replace('-', '')
            this.vatno = vat_number
            const accessKey = 'c14cce7738efb19a9728292b361a0685'

            let geturl = 'https://apilayer.net/api/validate'
            geturl = `${geturl}?vat_number=${vat_number}`
            geturl = `${geturl}&access_key=${accessKey}`

            const resVat = axios({
              url: geturl,
              adapter: jsonpAdapter,
              method: 'get',
            })

            const resVatData = await resVat.then(result => result).catch(error => error)
            const resData = resVatData.data

            if (resData.valid) {
              payload.vat_checked = true
              payload.vatno = vat_number
              if (resData.country_code !== this.country) {
                const msg = `According to the company VAT number you provided, this company's (${resData.company_name})   country is ${resData.country_code}. `
                      + 'Please double-check the company details or modify your VAT number accordingly to proceed. Source: https://ec.europa.eu/taxation_customs/vies/#/vat-validation'
                this.$bvToast.toast(msg, {
                  title: 'Wrong country',
                  variant: 'danger',
                  solid: true,
                  // make it last longer to allow user to read the message
                  autoHideDelay: 10000,
                })
                await new Promise(resolve => setTimeout(resolve, 1000))
                // Notify the user to use autofill function to fill in the form
                const msg2 = 'Please use autofill function to fill in the form.'
                this.$bvToast.toast(msg2, {
                  title: 'Suggestion',
                  variant: 'warning',
                  solid: true,
                  autoHideDelay: 10000,
                })
                this.spinnerUpdateAccount = false
                return false // eslint-disable-line
              }
            }
          }

          if (this.access_email !== this.access_email_same) {
            this.addAccessEmail(payload)
            this.getAccessEmail()
          }

          // if (this.customer.company !== payload.company && this.customer.company !== '' && payload.coperson !== 'individual') {
          //   this.notify({
          //     text: 'You have changed your company name, you will need to sign the DPA Agreement again.',
          //     variant: 'warning',
          //   })
          // }

          this.confirmationAlert('Are you sure you want to proceed with the changes?').then(async res => {
            if (res.isConfirmed) {
              this.spinnerUpdateAccount = true
              const uri = '/api/account/update_account'
              const response = await axios.post(uri, payload)
              if (response.status === 200) {
                if (response.data.status === 'upgrade') {
                  this.$router.push('/invoice/current')
                } else {
                  this.notify({
                    text: 'Account is updated.',
                    variant: 'success',
                  })
                  setTimeout(() => window.location.reload(), 1000)
                }
                this.spinnerUpdateAccount = false
              }
            }
          })
        } else {
          this.notify({
            text: 'Please fill all the form !',
            variant: 'warning',
          })
        }
      })
    },

    async cancelSubcription() {
      this.spinnerCancelSubs = true
      const uri = '/api/account/cancel_subscription'
      try {
        const response = await axios.post(uri)
        if (response.status === 200) {
          this.spinnerCancelSubs = false
          this.$router.push('/home')
        }
      } catch (err) {
        // Error handling here
        console.log(err)
        this.spinnerCancelSubs = false
        this.$router.push('/home')
      }
    },

    async renewSubscription() {
      this.spinnerRenewSubs = true
      const uri = '/api/account/renew_subscription'
      try {
        const response = await axios.post(uri)
        if (response.status === 200) {
          const id = response.data.invoiceId
          this.spinnerRenewSubs = false
          this.$router.push(`/invoice/current/${id}`)
        }
      } catch (err) {
        // Error handling here
        console.log(err)
        this.spinnerRenewSubs = false
        this.$router.push('/invoice/current')
      }
    },

    async addCard() {
      this.spinnerAddCC = true
      const uri = `/api/euplatesc/add_card_init?isRecurrent=${true}&justStore=${true}`
      const response = await axios.get(uri)
      if (response.status === 200) {
        const { data } = response

        // formData object is just use to map the data properly,
        // or you can use regular array.
        const formData = new FormData()
        formData.append('amount', data.amount)
        formData.append('curr', data.curr)
        formData.append('invoice_id', data.invoice_id)
        formData.append('order_desc', data.order_desc)
        formData.append('merch_id', data.merch_id)
        formData.append('timestamp', data.timestamp)
        formData.append('nonce', data.nonce)
        formData.append('recurent_freq', data.recurent_freq)
        formData.append('recurent_exp', data.recurent_exp)
        formData.append('recurent', data.recurent)
        formData.append('email', data.email)
        formData.append('lang', data.lang)
        formData.append('fp_hash', data.fp_hash)
        formData.append('ExtraData[silenturl]', data.silenturl)

        // create a actual form
        const ccForm = document.createElement('form')
        ccForm.method = 'post'
        ccForm.action = data.actionUrL
        ccForm.id = 'ccForm_to_submit'

        // create hidden input
        for (const [key, value] of formData) { // eslint-disable-line
          const ccInput = document.createElement('input')
          ccInput.type = 'hidden'
          ccInput.name = key
          ccInput.value = value
          ccForm.appendChild(ccInput)
        }

        // append this to the body and submit the form.
        document.body.appendChild(ccForm)
        document.getElementById('ccForm_to_submit').submit()
      }
    },

    async removeCard() {
      this.spinnerRemoveCC = true
      if (this.isRecurring) {
        const checkDefault = await this.checkIsDefaultCreditCard()
        if (checkDefault) return false
      }

      // card index
      let cardIndex = 0

      // get index
      this.euplatescCards.forEach((obj, index) => {
        if (obj.value === this.selectedCard) cardIndex = index
      })

      // get card name
      const name = this.euplatescCards[cardIndex].text
      const id = this.euplatescCards[cardIndex].value

      await this.confirmationAlert(`Are you sure you want to delete ${name}?`)
        .then(async res => {
          if (res.isConfirmed) {
            const payload = {
              cardId: id,
            }

            const uri = '/api/euplatesc/delete_card'
            const response = await axios.post(uri, payload)
            if (response.status === 200) {
              this.notify({
                text: 'Card is successfully deleted. Please select another default card.',
                variant: 'success',
              })
              this.$router.go(0)
            }
          }
        })
      // Cancel the subscription for the user if he deletes the card
      try {
        const uri = '/api/euplatesc/disable_auto_pay'
        const res2 = await axios.post(uri)
        if (res2.status === 200) {
          this.spinnerDisabledAutoPayCC = false
          this.$router.go(0)
        }
      } catch (err) {
        this.notify({
          text: 'Unable to disable automatic payment feature. Please contact us.',
          variant: 'danger',
        })
        this.spinnerDisabledAutoPayCC = false
      }
      return true
    },

    checkIsDefaultCreditCard() {
      let isDefault = false
      if (this.settings.euplatesc_card_id === this.selectedCard) {
        isDefault = true
        let message = ''
        if (this.euplatescCards.length === 1) {
          this.showSetDefaultBtn = false
          message = 'Please disable the recurring payments before deleting this card.'
        } else if (this.euplatescCards.length > 1) {
          this.showSetDefaultBtn = true
          message = `This card is your recurring payment card. Our payment processor will charge this
                    card for your following TrafficManager invoices. Please select another card main
                    card or disable the recurring payments before deleting this card.`
          this.setDefaultBtn()
        }
        this.notify({ text: message, variant: 'danger' })
        this.spinnerRemoveCC = false
      }

      return isDefault
    },

    async selectCard() {
      const payload = { cardId: this.selectedCard }
      const uri = '/api/euplatesc/change_default_card'
      await axios.post(uri, payload)
      this.$router.go(0)
    },

    cancelCardSubscription() {
      this.spinnerDisabledAutoPayCC = true
      this.confirmationAlert('This action will disable automatic payment, and enable manual payment of your invoice. Are you sure to proceed?')
        .then(async res => {
          if (res.isConfirmed) {
            try {
              const uri = '/api/euplatesc/disable_auto_pay'
              const res2 = await axios.post(uri)
              if (res2.status === 200) {
                this.spinnerDisabledAutoPayCC = false
                this.$router.go(0)
              }
            } catch (err) {
              this.notify({
                text: 'Unable to disable automatic payment feature. Please contact us.',
                variant: 'danger',
              })
              this.spinnerDisabledAutoPayCC = false
            }
          }
        })
    },

    enableCardSubscription() {
      this.spinnerEnabledAutoPayCC = true
      this.confirmationAlert('This action will enable automatic payment of your invoice with your Card. Are you sure to proceed?')
        .then(async res => {
          if (res.isConfirmed) {
            try {
              const uri = '/api/euplatesc/enable_auto_pay'
              const res2 = await axios.post(uri)
              if (res2.status === 200) {
                this.spinnerEnabledAutoPayCC = false
                this.$router.go(0)
              }
            } catch (err) {
              this.notify({
                text: 'Unable to enable automatic payment of your invoice with your Card.',
                variant: 'danger',
              })
              this.spinnerEnabledAutoPayCC = false
            }
          }
        })
    },

    cancelPaypalSubscription() {
      this.spinnerDisabledAutoPayPal = true
      this.confirmationAlert('This action will disable automatic payment, and enable manaul payment of your invoice. Are you sure to proceed?')
        .then(async res => {
          if (res.isConfirmed) {
            try {
              const uri = '/api/paypal/disable_auto_pay'
              const res2 = await axios.post(uri)
              if (res2.status === 200) {
                this.spinnerDisabledAutoPayPal = false
                this.$router.go(0)
              }
            } catch (err) {
              this.notify({
                text: 'Unable to disable the recurring payment of your paypal account.',
                variant: 'danger',
              })
              this.spinnerDisabledAutoPayPal = false
            }
          }
        })
    },

    enablePaypalSubscription() {
      this.spinnerEnabledAutoPayPal = true
      this.confirmationAlert('This action will enable automatic payment of your invoice with your Paypal. Are you sure to proceed?')
        .then(async res => {
          if (res.isConfirmed) {
            const payload = {
              subscriptionID: this.paypalPlanId,
            }
            try {
              const uri = '/api/paypal/enable_auto_pay'
              const res2 = await axios.post(uri, payload)
              if (res2.status === 200) {
                this.spinnerEnabledAutoPayPal = false
                this.$router.go(0)
              }
            } catch (err) {
              this.notify({
                text: 'Unable to enable automatic payment of your invoice with your Paypal.',
                variant: 'danger',
              })
              this.spinnerEnabledAutoPayPal = false
            }
          }
        })
    },

    formatData(dataToFormat, isRegion = false) {
      const formatedDataForSelect = []
      dataToFormat.forEach(obj=>{ // eslint-disable-line
        if (obj.name !== '') {
          const data = {
            text: obj.name,
            value: isRegion? obj.id : obj.code // eslint-disable-line
          }
          formatedDataForSelect.push(data)
        }
      })
      return formatedDataForSelect
    },
  },
}
</script>

<style lang="scss" scoped>
.customMarginAgreement {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.ch-title {
  position: relative;
  top: -2px;
}

.warning-section {
  background: #f44336;
  color: #fff;
}
.card .card-header{
  height: 15px !important;
  line-height: 15px !important;
}

// make adjustments for small screens
@media screen and (max-width: 500px) {
  .adjustments {
    display: flex;
    flex-direction: column;
  }
  .adjustments > button {
    width:min-content;
    margin:0 auto;
  }
  .adjustments2 {
    margin:0;
  }
}
</style>

<style>
.dark-layout select.form-control, .dark-layout .custom-select {
  width: fit-content !important;
}
[dir] .custom-select {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: fit-content !important;
}
</style>
